import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/Layouts/Blog/BlogPost/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`You are usually working with other developers in projects while sharing your code using Git.`}</p>
    <p>{`It is a good thing that if you can keep track of the Git history while developing in your editor. You could double check who modified a particular file last, compare the file with previous version of the same file and so on.`}</p>
    <p><img parentName="p" {...{
        "src": "https://miro.medium.com/max/1400/1*u5sdvk9CXxdUHnV35PQOwA.png",
        "alt": "Gitlens"
      }}></img></p>
    <p>{`I use Visual Studio Code for my daily coding. So, how would you do that?`}</p>
    <h2>{`Enter GitLens`}</h2>
    <blockquote>
      <p parentName="blockquote">{`“GitLens supercharges the Git capabilities built into Visual Studio Code.”`}</p>
    </blockquote>
    <p>{`GitLens offers visualization of code authorship, navigate and explore git repositories, and then gain insights via powerful comparison commands.`}</p>
    <p>{`You will be able to go back through history and gain further insights and see how your code evolved.
Evolution of your codebase is important when you are introduced to a new codebase as developer: what to why other developers arrived to the current version of that particular code.`}</p>
    <p><img parentName="p" {...{
        "src": "https://miro.medium.com/max/2000/1*C1MMYYDrLM02aeSUytReJw.gif",
        "alt": "Gitlens"
      }}></img></p>
    <p>{`Some of the features offered by GitLens (from the GitLens website):`}</p>
    <ul>
      <li parentName="ul">{`🦄 a `}<a parentName="li" {...{
          "href": "https://github.com/eamodio/vscode-gitlens/#gitlens-history-explorer"
        }}>{`GitLens History explorer`}</a>{`: It will let you navigate file histories in your project.`}</li>
      <li parentName="ul">{`🦄 Authorship: It will show the most recent commit. It will also show the number of authors for a given code block.`}</li>
      <li parentName="ul">{`🦄 `}<a parentName="li" {...{
          "href": "https://github.com/eamodio/vscode-gitlens/#gutter-blame"
        }}>{`Gutter blame`}</a>{`: You will have annotations, including a heatmap, for the whole file`}</li>
      <li parentName="ul">{`🦄 `}<a parentName="li" {...{
          "href": "https://github.com/eamodio/vscode-gitlens/#commit-search"
        }}>{`Commit search`}</a>{`: You will be able to search commits by message, author, filename, commit id`}</li>
      <li parentName="ul">{`🦄 and so much `}<a parentName="li" {...{
          "href": "https://github.com/eamodio/vscode-gitlens/#features"
        }}>{`more`}</a></li>
    </ul>
    <p>{`Thanks to GitLens you will understand your code better and it will help you to have a glimpse into whom, why and when a line of code was changed.`}</p>
    <p>{`Which extensions are you using with your own editor?`}</p>
    <h4>{`FOLLOW ME`}</h4>
    <p>{`Do you know that I have a YouTube channel? `}<a parentName="p" {...{
        "href": "http://bit.ly/YT_DOMENICOSOLAZZO"
      }}>{`Subscribe!`}</a></p>
    <p>{`Where can you find me?`}</p>
    <ul>
      <li parentName="ul">{`Youtube: `}<a parentName="li" {...{
          "href": "http://bit.ly/YT_DOMENICOSOLAZZO"
        }}>{`Domenico Solazzo's Channel`}</a></li>
      <li parentName="ul">{`Instagram: `}<a parentName="li" {...{
          "href": "https://www.instagram.com/domenicosolazzo/"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Linkedin: `}<a parentName="li" {...{
          "href": "https://www.linkedin.com/in/solazzo/"
        }}>{`solazzo`}</a></li>
      <li parentName="ul">{`Medium: `}<a parentName="li" {...{
          "href": "https://medium.com/@domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Facebook: `}<a parentName="li" {...{
          "href": "https://www.facebook.com/domenicosolazzo.labs/"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Snapchat: `}<a parentName="li" {...{
          "href": "https://twitter.com/domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Github: `}<a parentName="li" {...{
          "href": "https://github.com/domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Website: `}<a parentName="li" {...{
          "href": "https://www.domenicosolazzo.com"
        }}>{`https://www.domenicosolazzo.com`}</a></li>
      <li parentName="ul">{`Hashnode: `}<a parentName="li" {...{
          "href": "https://hashnode.com/@domenicosolazzo"
        }}>{`https://hashnode.com/@domenicosolazzo`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      